<template>
  <ics-page-inner :title="titleName">
    <template slot="btn-inner">
      <div class="btn-inner">
        <el-button type="primary" @click="download('OneKey')">
          一键下载
        </el-button>
      </div>
    </template>
    <template>
      <el-col :span="24">
        <span>企业资料/企业资料</span>
        <el-divider content-position="right">
          <el-button type="primary" @click="download('enterprise')">
            下载
          </el-button>
        </el-divider>
      </el-col>
      <el-col v-if="coreDetail.fileFirmDto" :span="24">
        <file-list
          list-type="picture-card"
          :files="coreDetail.fileFirmDto.firmList"
          :handle-preview="(val)=> previewFile(val, 'firm')"
          :props="{name: 'typeName', src: 'url'}"
        />
      </el-col>
      <el-col :span="24" style="margin-top: 5px;">
        <span>资产资料</span>
        <el-divider content-position="right">
          <el-button type="primary" @click="download('assets')">
            下载
          </el-button>
        </el-divider>
        <el-col :span="24" style="margin-top: 5px;">
          <span>资产资料/基础合同资料</span>
          <el-divider content-position="right">
            <el-button type="primary" @click="download('coreAssetsBasic')">
              下载
            </el-button>
          </el-divider>
        </el-col>
        <el-col v-if="coreDetail.assetsDto" :span="24">
          <file-list
            list-type="picture-card"
            :files="coreDetail.assetsDto.contractFile"
            :handle-preview="(val)=> previewFile(val, 'other')"
            :props="{name: 'typeName', src: 'url'}"
          />
        </el-col>
        <el-col :span="24" style="margin-top: 5px;">
          <span>资产资料/应付账款资料</span>
          <el-divider content-position="right" @click="download('coreAssetsRe')">
            <el-button type="primary">
              下载
            </el-button>
          </el-divider>
        </el-col>
        <el-col v-if="coreDetail.assetsDto" :span="24">
          <file-list
            list-type="picture-card"
            :files="coreDetail.assetsDto.receivableFile"
            :handle-preview="(val)=> previewFile(val, 'other')"
            :props="{name: 'typeName', src: 'url'}"
          />
        </el-col>
        <el-col :span="24" style="margin-top: 5px;">
          <span>资产资料/发票资料</span>
          <el-divider content-position="right">
            <el-button type="primary" @click="download('coreAssetsInvoice')">
              下载
            </el-button>
          </el-divider>
        </el-col>
        <el-col v-if="coreDetail.assetsDto" :span="24">
          <file-list
            list-type="picture-card"
            :files="coreDetail.assetsDto.invoiceFile"
            :handle-preview="(val)=> previewFile(val, 'invoice')"
            :props="{name: 'typeName', src: 'url'}"
          />
        </el-col>
      </el-col>
      <el-col :span="24" style="margin-top: 5px;">
        <span>授信资料</span>
        <el-divider content-position="right">
          <el-button type="primary" @click="download('creditCore')">
            下载
          </el-button>
        </el-divider>
        <el-col :span="24" style="margin-top: 5px;">
          <el-col :span="24" style="margin-top: 5px;">
            <span>授信资料/合同签署资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('creditCoreCon')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="coreDetail.creditDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="coreDetail.creditDto.contractFile"
              :handle-preview="(val)=> previewFile(val, 'concat')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>授信资料/附件资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('creditCoreEnc')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="coreDetail.creditDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="coreDetail.creditDto.enclosureFile"
              :handle-preview="(val)=> previewFile(val, 'credit')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>授信资料/基础合同资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('coreBasic')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="coreDetail.creditDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="coreDetail.creditDto.basicContractFile"
              :handle-preview="(val)=> previewFile(val, 'other')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>授信资料/发票资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('coreInvoice')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="coreDetail.creditDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="coreDetail.creditDto.invoiceFile"
              :handle-preview="(val)=> previewFile(val, 'invoice')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
        </el-col>
      </el-col>
      <el-col :span="24" style="margin-top: 5px;">
        <span>用信资料</span>
        <el-divider content-position="right">
          <el-button type="primary" @click="download('useCore')">
            下载
          </el-button>
        </el-divider>
        <el-col :span="24" style="margin-top: 5px;">
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/合同签署资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCoreContract')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="coreDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="coreDetail.useDto.contractFile"
              :handle-preview="(val)=> previewFile(val, 'other')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/附件资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCoreEnclosure')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="coreDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="coreDetail.useDto.enclosureFile"
              :handle-preview="(val)=> previewFile(val, 'use')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/应收账款资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('coreRe')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="coreDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="coreDetail.useDto.reFile"
              :handle-preview="(val)=> previewFile(val, 'other')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/应收发票资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCoreReInvoice')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="coreDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="coreDetail.useDto.reInvoiceFile"
              :handle-preview="(val)=> previewFile(val, 'invoice')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/放款凭证</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCoreCollection')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="coreDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="coreDetail.useDto.loanFile"
              :handle-preview="(val)=> previewFile(val, 'use')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/收款凭证</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCoreLoan')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="coreDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="coreDetail.useDto.collectionFile"
              :handle-preview="(val)=> previewFile(val, 'use')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
        </el-col>
      </el-col>
      <el-col :span="24" style="margin-top: 5px;">
        <span>付款资料</span>
        <el-divider content-position="right">
          <el-button type="primary" @click="download('repaymentReCo')">
            下载
          </el-button>
        </el-divider>
        <el-col :span="24" style="margin-top: 5px;">
          <el-col :span="24" style="margin-top: 5px;">
            <span>付款资料/付款凭证资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('repaymentCoreCo')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="coreDetail.repaymentDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="coreDetail.repaymentDto.pay"
              :handle-preview="(val)=> previewFile(val, 'repay')"
              :props="{name: 'typeCode', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>付款资料/收款凭证资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('repaymentCorePay')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="coreDetail.repaymentDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="coreDetail.repaymentDto.co"
              :handle-preview="(val)=> previewFile(val, 'repay')"
              :props="{name: 'typeCode', src: 'url'}"
            />
          </el-col>
        </el-col>
      </el-col>
    </template>
    <div class="partition-area">
      <div v-for="(item, index) in coreDetail.customerDto" :key="index">
        <ics-page-inner :title="item.fileFirmDto.name">
          <template>
            <el-col :span="24">
              <span>企业资料/企业资料</span>
              <el-divider content-position="right">
                <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'customerFirm')">
                  下载
                </el-button>
              </el-divider>
            </el-col>
            <el-col v-if="item.fileFirmDto" :span="24">
              <file-list
                list-type="picture-card"
                :files="item.fileFirmDto.firmList"
                :handle-preview="(val)=> previewFile(val, 'firm')"
                :props="{name: 'typeName', src: 'url'}"
              />
            </el-col>
            <el-col :span="24" style="margin-top: 5px;">
              <span>资产资料</span>
              <el-divider content-position="right">
                <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'assetsCustomer')">
                  下载
                </el-button>
              </el-divider>
              <el-col :span="24" style="margin-top: 5px;">
                <span>资产资料/基础合同资料</span>
                <el-divider content-position="right">
                  <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'assetsCustomerBasic')">
                    下载
                  </el-button>
                </el-divider>
              </el-col>
              <el-col v-if="item.assetsDto" :span="24">
                <file-list
                  list-type="picture-card"
                  :files="item.assetsDto.contractFile"
                  :handle-preview="(val)=> previewFile(val, 'other')"
                  :props="{name: 'typeName', src: 'url'}"
                />
              </el-col>
              <el-col :span="24" style="margin-top: 5px;">
                <span>资产资料/应付账款资料</span>
                <el-divider content-position="right">
                  <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'assetsCustomerRe')">
                    下载
                  </el-button>
                </el-divider>
              </el-col>
              <el-col v-if="item.assetsDto" :span="24">
                <file-list
                  list-type="picture-card"
                  :files="item.assetsDto.receivableFile"
                  :handle-preview="(val)=> previewFile(val, 'other')"
                  :props="{name: 'typeName', src: 'url'}"
                />
              </el-col>
              <el-col :span="24" style="margin-top: 5px;">
                <span>资产资料/发票资料</span>
                <el-divider content-position="right" @click="customerDownload(item.fileFirmDto.firmId, 'assetsCustomerInvoice')">
                  <el-button type="primary">
                    下载
                  </el-button>
                </el-divider>
              </el-col>
              <el-col v-if="item.assetsDto" :span="24">
                <file-list
                  list-type="picture-card"
                  :files="item.assetsDto.invoiceFile"
                  :handle-preview="(val)=> previewFile(val, 'invoice')"
                  :props="{name: 'typeName', src: 'url'}"
                />
              </el-col>
            </el-col>
            <el-col :span="24" style="margin-top: 5px;">
              <span>授信资料</span>
              <el-divider content-position="right">
                <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'creditCustomer')">
                  下载
                </el-button>
              </el-divider>
              <el-col :span="24" style="margin-top: 5px;">
                <el-col :span="24" style="margin-top: 5px;">
                  <span>授信资料/合同签署资料</span>
                  <el-divider content-position="right">
                    <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'creditCustomerCon')">
                      下载
                    </el-button>
                  </el-divider>
                </el-col>
                <el-col v-if="item.creditDto" :span="24">
                  <file-list
                    list-type="picture-card"
                    :files="item.creditDto.contractFile"
                    :handle-preview="(val)=> previewFile(val, 'concat')"
                    :props="{name: 'typeName', src: 'url'}"
                  />
                </el-col>
                <el-col :span="24" style="margin-top: 5px;">
                  <span>授信资料/附件资料</span>
                  <el-divider content-position="right">
                    <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'creditCustomerEnc')">
                      下载
                    </el-button>
                  </el-divider>
                </el-col>
                <el-col v-if="item.creditDto" :span="24">
                  <file-list
                    list-type="picture-card"
                    :files="item.creditDto.enclosureFile"
                    :handle-preview="(val)=> previewFile(val, 'credit')"
                    :props="{name: 'typeName', src: 'url'}"
                  />
                </el-col>
                <el-col :span="24" style="margin-top: 5px;">
                  <span>授信资料/基础合同资料</span>
                  <el-divider content-position="right">
                    <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'creditCustomerBasic')">
                      下载
                    </el-button>
                  </el-divider>
                </el-col>
                <el-col v-if="item.creditDto" :span="24">
                  <file-list
                    list-type="picture-card"
                    :files="item.creditDto.basicContractFile"
                    :handle-preview="(val)=> previewFile(val, 'other')"
                    :props="{name: 'typeName', src: 'url'}"
                  />
                </el-col>
                <el-col :span="24" style="margin-top: 5px;">
                  <span>授信资料/发票资料</span>
                  <el-divider content-position="right">
                    <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'creditCustomerInvoice')">
                      下载
                    </el-button>
                  </el-divider>
                </el-col>
                <el-col v-if="item.creditDto" :span="24">
                  <file-list
                    list-type="picture-card"
                    :files="item.creditDto.invoiceFile"
                    :handle-preview="(val)=> previewFile(val, 'invoice')"
                    :props="{name: 'typeName', src: 'url'}"
                  />
                </el-col>
              </el-col>
            </el-col>
            <el-col :span="24" style="margin-top: 5px;">
              <span>用信资料</span>
              <el-divider content-position="right">
                <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'useCustomer')">
                  下载
                </el-button>
              </el-divider>
              <el-col :span="24" style="margin-top: 5px;">
                <el-col :span="24" style="margin-top: 5px;">
                  <span>用信资料/合同签署资料</span>
                  <el-divider content-position="right">
                    <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'useCustomerContract')">
                      下载
                    </el-button>
                  </el-divider>
                </el-col>
                <el-col v-if="item.useDto" :span="24">
                  <file-list
                    list-type="picture-card"
                    :files="item.useDto.contractFile"
                    :handle-preview="(val)=> previewFile(val, 'concat')"
                    :props="{name: 'typeName', src: 'url'}"
                  />
                </el-col>
                <el-col :span="24" style="margin-top: 5px;">
                  <span>用信资料/附件资料</span>
                  <el-divider content-position="right">
                    <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'useCustomerEnclosure')">
                      下载
                    </el-button>
                  </el-divider>
                </el-col>
                <el-col v-if="item.useDto" :span="24">
                  <file-list
                    list-type="picture-card"
                    :files="item.useDto.enclosureFile"
                    :handle-preview="(val)=> previewFile(val, 'use')"
                    :props="{name: 'typeName', src: 'url'}"
                  />
                </el-col>
                <el-col :span="24" style="margin-top: 5px;">
                  <span>用信资料/应收账款资料</span>
                  <el-divider content-position="right">
                    <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'useCustomerRe')">
                      下载
                    </el-button>
                  </el-divider>
                </el-col>
                <el-col v-if="item.useDto" :span="24">
                  <file-list
                    list-type="picture-card"
                    :files="item.useDto.reFile"
                    :handle-preview="(val)=> previewFile(val, 'other')"
                    :props="{name: 'typeName', src: 'url'}"
                  />
                </el-col>
                <el-col :span="24" style="margin-top: 5px;">
                  <span>用信资料/应收发票资料</span>
                  <el-divider content-position="right">
                    <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'useCustomerReInvoice')">
                      下载
                    </el-button>
                  </el-divider>
                </el-col>
                <el-col v-if="item.useDto" :span="24">
                  <file-list
                    list-type="picture-card"
                    :files="item.useDto.reInvoiceFile"
                    :handle-preview="(val)=> previewFile(val, 'invoice')"
                    :props="{name: 'typeName', src: 'url'}"
                  />
                </el-col>
                <el-col :span="24" style="margin-top: 5px;">
                  <span>用信资料/放款凭证</span>
                  <el-divider content-position="right">
                    <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'useCustomerVoucher')">
                      下载
                    </el-button>
                  </el-divider>
                </el-col>
                <el-col v-if="item.useDto" :span="24">
                  <file-list
                    list-type="picture-card"
                    :files="item.useDto.loanFile"
                    :handle-preview="(val)=> previewFile(val, 'use')"
                    :props="{name: 'typeName', src: 'url'}"
                  />
                </el-col>
                <el-col :span="24" style="margin-top: 5px;">
                  <span>用信资料/收款凭证</span>
                  <el-divider content-position="right">
                    <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'useCustomerCollection')">
                      下载
                    </el-button>
                  </el-divider>
                </el-col>
                <el-col v-if="item.useDto" :span="24">
                  <file-list
                    list-type="picture-card"
                    :files="item.useDto.collectionFile"
                    :handle-preview="(val)=> previewFile(val, 'use')"
                    :props="{name: 'typeName', src: 'url'}"
                  />
                </el-col>
              </el-col>
            </el-col>
            <el-col :span="24" style="margin-top: 5px;">
              <span>付款资料</span>
              <el-divider content-position="right">
                <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'repaymentCustomer')">
                  下载
                </el-button>
              </el-divider>
              <el-col :span="24" style="margin-top: 5px;">
                <el-col :span="24" style="margin-top: 5px;">
                  <span>付款资料/付款凭证资料</span>
                  <el-divider content-position="right">
                    <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'repaymentCustomerCo')">
                      下载
                    </el-button>
                  </el-divider>
                </el-col>
                <el-col v-if="item.repaymentDto" :span="24">
                  <file-list
                    list-type="picture-card"
                    :files="item.repaymentDto.pay"
                    :handle-preview="(val)=> previewFile(val, 'repay')"
                    :props="{name: 'typeCode', src: 'url'}"
                  />
                </el-col>
                <el-col :span="24" style="margin-top: 5px;">
                  <span>付款资料/收款凭证资料</span>
                  <el-divider content-position="right">
                    <el-button type="primary" @click="customerDownload(item.fileFirmDto.firmId, 'repaymentCustomerPay')">
                      下载
                    </el-button>
                  </el-divider>
                </el-col>
                <el-col v-if="item.repaymentDto" :span="24">
                  <file-list
                    list-type="picture-card"
                    :files="item.repaymentDto.co"
                    :handle-preview="(val)=> previewFile(val, 'repay')"
                    :props="{name: 'typeCode', src: 'url'}"
                  />
                </el-col>
              </el-col>
            </el-col>
          </template>
        </ics-page-inner>
      </div>
    </div>
  </ics-page-inner>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
export default {
  name: 'IcsCoreInfoInner',
  components: {},
  mixins: [routeEnterMixin],
  props: {
    titleName: {
      type: String,
      default: ''
    },
    firmId: {
      type: [Number,String],
      default: ''
    },
    code: {
      type: [Number,String],
      default: ''
    },
    coreInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      coreDetail: {}
    }
  },
  watch: {
    coreInfo: {
      handler (val) {
        if (val) {
          console.log(val)
          this.coreDetail = val
        }
      },
      immediate: true
    },
  },
  created() {
    console.log(this.coreInfo)
  },
  methods: {
    previewFile (file, bucket) {
      this.utils.downloadP(bucket, file.url)
    },
    download (type) {
      if (type === 'OneKey') {
        this.api.system.archives.coreDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'enterprise') {
        this.api.system.archives.firmDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'assets') {
        this.api.system.archives.assetsCoreDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'coreAssetsBasic') {
        this.api.system.archives.assetsCoreDownloadBasic(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'coreAssetsRe') {
        this.api.system.archives.assetsCoreDownloadRe(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'coreAssetsInvoice') {
        this.api.system.archives.assetsCoreDownloadInvoice(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'coreBasic') {
        this.api.system.archives.creditCoreBasicDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'coreRe') {
        this.api.system.archives.useCoreReDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'coreInvoice') {
        this.api.system.archives.creditCoreInvoiceDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditCore') {
        this.api.system.archives.creditCoreDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditCoreCon') {
        this.api.system.archives.creditCoreConDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditCoreEnc') {
        this.api.system.archives.creditCoreEncDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      }else if (type === 'useCore') {
        this.api.system.archives.useCoreDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCoreContract') {
        this.api.system.archives.useCoreContractDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCoreEnclosure') {
        this.api.system.archives.useCoreEnclosureDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCoreReInvoice') {
        this.api.system.archives.useCoreReInvoiceDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCoreCollection') {
        this.api.system.archives.useCoreCollectionDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCoreLoan') {
        this.api.system.archives.useCoreLoanDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'repaymentReCo') {
        this.api.system.archives.repaymentReCoDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'repaymentCoreCo') {
        this.api.system.archives.repaymentCoreCoDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'repaymentCorePay') {
        this.api.system.archives.repaymentCorePayDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else {
        return false
      }
    },
    customerDownload (val, type) {
      if (type === 'customerFirm') {
        this.api.system.archives.customerFirmDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'assetsCustomer') {
        this.api.system.archives.assetsCustomerDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'assetsCustomerBasic') {
        this.api.system.archives.assetsCustomerDownloadBasic(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'assetsCustomerRe') {
        this.api.system.archives.assetsCustomerDownloadRe(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'assetsCustomerInvoice') {
        this.api.system.archives.assetsCustomerDownloadInvoice(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditCustomer') {
        this.api.system.archives.creditCustomerDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditCustomerCon') {
        this.api.system.archives.creditCustomerConDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditCustomerEnc') {
        this.api.system.archives.creditCustomerEncDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditCustomerBasic') {
        this.api.system.archives.creditCustomerBasicDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditCustomerInvoice') {
        this.api.system.archives.creditCustomerInvoiceDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCustomer') {
        this.api.system.archives.useCustomerDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCustomerContract') {
        this.api.system.archives.useCustomerContractDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCustomerEnclosure') {
        this.api.system.archives.useCustomerEnclosureDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCustomerRe') {
        this.api.system.archives.useCustomerReDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCustomerReInvoice') {
        this.api.system.archives.useCustomerReInvoiceDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCustomerVoucher') {
        this.api.system.archives.useCustomerVoucherDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCustomerCollection') {
        this.api.system.archives.useCustomerCollectionDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'repaymentCustomer') {
        this.api.system.archives.repaymentCustomerDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'repaymentCustomerCo') {
        this.api.system.archives.repaymentCustomerCoDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'repaymentCustomerPay') {
        this.api.system.archives.repaymentCustomerPayDownload(val).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>
