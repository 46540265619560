<template>
  <ics-page-inner :title="titleName">
    <template slot="btn-inner">
      <div class="btn-inner">
        <el-button type="primary" @click="download('OneKey')">
          一键下载
        </el-button>
      </div>
    </template>
    <template>
      <el-col :span="24" style="margin-top: 5px;">
        <span>授信资料</span>
        <el-divider content-position="right">
          <el-button type="primary" @click="download('creditCredit')">
            下载
          </el-button>
        </el-divider>
        <el-col :span="24" style="margin-top: 5px;">
          <el-col :span="24" style="margin-top: 5px;">
            <span>授信资料/合同签署资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('creditCon')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="creditDetail" :span="24">
            <file-list
              list-type="picture-card"
              :files="creditDetail.contractFile"
              :handle-preview="(val)=> previewFile(val, 'concat')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>授信资料/附件资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('creditEnc')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="creditDetail" :span="24">
            <file-list
              list-type="picture-card"
              :files="creditDetail.enclosureFile"
              :handle-preview="(val)=> previewFile(val, 'credit')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>授信资料/基础合同资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('creditBasic')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="creditDetail" :span="24">
            <file-list
              list-type="picture-card"
              :files="creditDetail.basicContractFile"
              :handle-preview="(val)=> previewFile(val, 'other')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>授信资料/发票资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('creditInvoice')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col :span="24">
            <file-list
              list-type="picture-card"
              :files="creditDetail.invoiceFile"
              :handle-preview="(val)=> previewFile(val, 'invoice')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
        </el-col>
      </el-col>
      <el-col :span="24" style="margin-top: 5px;">
        <span>用信资料</span>
        <el-divider content-position="right">
          <el-button type="primary" @click="download('creditUse')">
            下载
          </el-button>
        </el-divider>
        <el-col :span="24" style="margin-top: 5px;">
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/合同签署资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCreditContract')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="creditDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="creditDetail.useDto.contractFile"
              :handle-preview="(val)=> previewFile(val, 'concat')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/附件资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCreditEnclosure')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="creditDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="creditDetail.useDto.enclosureFile"
              :handle-preview="(val)=> previewFile(val, 'use')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/应收账款资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCreditRe')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="creditDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="creditDetail.useDto.reFile"
              :handle-preview="(val)=> previewFile(val, 'other')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/应收发票资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCreditReInvoice')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="creditDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="creditDetail.useDto.reInvoiceFile"
              :handle-preview="(val)=> previewFile(val, 'invoice')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/放款凭证</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCreditVoucher')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="creditDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="creditDetail.useDto.loanFile"
              :handle-preview="(val)=> previewFile(val, 'use')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/收款凭证</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCreditCollection')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="creditDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="creditDetail.useDto.collectionFile"
              :handle-preview="(val)=> previewFile(val, 'use')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
        </el-col>
      </el-col>
      <el-col :span="24" style="margin-top: 5px;">
        <span>付款资料</span>
        <el-divider content-position="right">
          <el-button type="primary" @click="download('repaymentCredit')">
            下载
          </el-button>
        </el-divider>
        <el-col :span="24" style="margin-top: 5px;">
          <el-col :span="24" style="margin-top: 5px;">
            <span>付款资料/付款凭证资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('repaymentCreditCo')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="creditDetail.repaymentDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="creditDetail.repaymentDto.pay"
              :handle-preview="(val)=> previewFile(val, 'repay')"
              :props="{name: 'typeCode', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>付款资料/收款凭证资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('repaymentCreditPay')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="creditDetail.repaymentDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="creditDetail.repaymentDto.co"
              :handle-preview="(val)=> previewFile(val, 'repay')"
              :props="{name: 'typeCode', src: 'url'}"
            />
          </el-col>
        </el-col>
      </el-col>
    </template>
  </ics-page-inner>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
export default {
  name: 'IcsCreditInfoInner',
  components: {},
  mixins: [routeEnterMixin],
  props: {
    titleName: {
      type: String,
      default: ''
    },
    code: {
      type: [Number,String],
      default: ''
    },
    creditInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      creditDetail: {}
    }
  },
  watch: {
    creditInfo: {
      handler (val) {
        if (val) {
          console.log(val)
          this.creditDetail = val
        }
      },
      immediate: true
    },
  },
  created() {},
  methods: {
    previewFile (file, bucket) {
      this.utils.downloadP(bucket, file.url)
    },
    download (type) {
      if (type === 'OneKey') {
        this.api.system.archives.creditDownload(this.code).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditCredit') {
        this.api.system.archives.creditCreditDownload(this.code).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditCon') {
        this.api.system.archives.creditConDownload(this.code).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditEnc') {
        this.api.system.archives.creditEncDownload(this.code).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditBasic') {
        this.api.system.archives.creditBasicDownload(this.code).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditInvoice') {
        this.api.system.archives.creditInvoiceDownload(this.code).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditUse') {
        this.api.system.archives.creditUseDownload(this.code).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCreditContract') {
        this.api.system.archives.useCreditContractDownload(this.code).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCreditEnclosure') {
        this.api.system.archives.useCreditEnclosureDownload(this.code).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCreditRe') {
        this.api.system.archives.useCreditReDownload(this.code).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCreditReInvoice') {
        this.api.system.archives.useCreditReInvoiceDownload(this.code).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCreditVoucher') {
        this.api.system.archives.useCreditVoucherDownload(this.code).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCreditCollection') {
        this.api.system.archives.useCreditCollectionDownload(this.code).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'repaymentCredit') {
        this.api.system.archives.repaymentCreditDownload(this.code).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'repaymentCreditCo') {
        this.api.system.archives.repaymentCreditCoDownload(this.code).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'repaymentCreditPay') {
        this.api.system.archives.repaymentCreditPayDownload(this.code).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>
