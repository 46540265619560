<template>
  <el-main>
    <page-title />
    <div class="app-container">
      <el-row :gutter="20">
        <el-col :span="7" :xs="24">
          <el-card style="margin-bottom:20px;">
            <el-input v-model="filterText" placeholder="输入关键字进行搜索" />
            <div style="height: 480px;overflow-y:auto">
              <div slot="header" class="clearfix" />
              <el-row class="tac">
                <el-col :span="24">
                  <el-tree
                    ref="tree"
                    class="filter-tree"
                    :data="firmListDtoList"
                    :props="defaultProps"
                    :current-node-key="currentNodeKey"
                    :filter-node-method="filterNode"
                    :default-expand-all="false"
                    @node-click="handleMoveCopyNodeClick"
                  />
                </el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
        <el-col :span="17" :xs="24">
          <el-card>
            <div style="height: 513px;overflow-y:auto;">
              <div v-if="currentType === 'core'" class="partition-area">
                <ics-core-info-inner :title-name="titleName" :firm-id="currentId" :core-info="coreDto" />
              </div>
              <div v-if="currentType === 'customer'" class="partition-area">
                <ics-customer-info-inner :title-name="titleName" :firm-id="currentId" :customer-info="customerOne" />
              </div>
              <div v-if="currentType === 'credit'" class="partition-area">
                <ics-credit-info-inner :title-name="titleName" :code="currentCode" :credit-info="creditDto" />
              </div>
              <div v-if="currentType === 'use'" class="partition-area">
                <ics-use-info-inner :title-name="titleName" :code="currentCode" :use-info="useDto" />
              </div>
              <el-backtop />
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </el-main>
</template>

<script>
import IcsCoreInfoInner from './components/core-info-inner'
import IcsCustomerInfoInner from './components/customer-info-inner'
import IcsCreditInfoInner from './components/credit-info-inner'
import IcsUseInfoInner from './components/use-info-inner'

export default {
  components: { IcsCoreInfoInner, IcsCustomerInfoInner, IcsCreditInfoInner, IcsUseInfoInner },
  data() {
    return {
      filterText: '',
      firmListDtoList: [],
      currentNodeKey: '',
      currentType: '',
      currentId: '',
      currentCode: '',
      titleName: '',
      coreDto: {},
      customerOne: {},
      creditDto: {},
      useDto: {},
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  created () {
    this.getFirmListDtoList()
  },
  methods: {
    getFirmListDtoList () {
      this.api.system.archives.getFirmListDtoList().then(result => {
        const firmListDtoList = result.data.data || []
        firmListDtoList.forEach(item1 => {
          item1.id = item1.coreId
          item1.label = item1.coreName
          item1.type = 'core'
          item1.code = item1.coreCode
          item1.parentCode = '-1'
          if (item1.customerList) {
            item1.customerList.forEach(item2 => {
              item2.id = item2.customerId
              item2.label = item2.customerName
              item2.type = 'customer'
              item2.code = item2.customerCode
              if (item2.creditList) {
                item2.creditList.forEach(item3 => {
                  item3.label = item3.creditCode
                  item3.code = item3.creditCode
                  item3.type = 'credit'
                  if (item3.use) {
                    item3.use.forEach(item4 => {
                      item4.label = item4.useCode
                      item4.code = item4.useCode
                      item4.type = 'use'
                    })
                    item3.children = item3.use
                  }
                })
                item2.children = item2.creditList
              }
            })
            item1.children = item1.customerList
          }
        })
        this.firmListDtoList = firmListDtoList || []
      }).finally(() => {
      })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    handleMoveCopyNodeClick(row) {
      const data = row || {}
      switch (data.type) {
        case 'core':
          this.currentType = data.type
          this.titleName = data.label
          this.currentId = data.id
          this.getCoreDto(data.id)
          break
        case 'customer':
          this.currentType = data.type
          this.titleName = data.label
          this.currentId = data.id
          this.getCustomerOne(data.id)
          break
        case 'credit':
          this.currentType = data.type
          this.titleName = data.label
          this.currentCode = data.code
          this.getCreditDto(data.code)
          break
        case 'use':
          this.currentType = data.type
          this.titleName = data.label
          this.currentCode = data.code
          this.getUseDto(data.code)
          break
        default:
          return false
      }
      this.currentNodeKey = data.id
    },
    getCoreDto (code) {
      this.api.system.archives.getCoreDto(code).then(result => {
        const data = result.data.data || {}
        this.coreDto = data
      }).finally(() => {
      })
    },
    getCustomerOne (code) {
      this.api.system.archives.getCustomerOne(code).then(result => {
        const data = result.data.data || {}
        this.customerOne = data
      }).finally(() => {
      })
    },
    getCreditDto (code) {
      this.api.system.archives.getCreditDto(code).then(result => {
        const data = result.data.data || {}
        this.creditDto = data
      }).finally(() => {
      })
    },
    getUseDto (code) {
      this.api.system.archives.getUseDto(code).then(result => {
        const data = result.data.data || {}
        this.useDto = data
      }).finally(() => {
      })
    },
  }
}
</script>

<style lang="less" scoped>
.app-container {
  padding: 20px;
}
.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}
.el-menu {
  border-right: solid 1px #ffffff;
}
.el-menu-item {
  border-radius:30px 0 0 30px;
}
.el-menu-item.is-active {
  border-radius:30px 0 0 30px;
  color: #FFFFFF;
  background-color: rgb(102, 177, 255);
}
.el-menu-item:focus, .el-menu-item:hover {
  outline: 0;
  color: #FFFFFF;
  background-color: rgb(102, 177, 255);
}
</style>
